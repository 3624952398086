<template>

  <div>

    <!--drink-list-add-new
      :is-add-new-drink-sidebar-active.sync="isAddNewDrinkSidebarActive"
      :machine-options="machineOptions"
      :company-options="companyOptions"
      @refetch-data="refetchData"
    /-->

    <!-- Filters -->
    <drinks-list-filters
      :company-filter.sync="companyFilter"
      :company-options="companyOptions"

      :object-filter.sync="objectFilter"
      :object-options="objectOptions"

      :machine-filter.sync="machineFilter"
      :machine-options="machineOptions"

      :range-picker.sync="rangePicker"
    />

    <b-tabs
      pills
    >

      <b-tab active>
        <template #title>
          <feather-icon
            icon="CpuIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('components.components') }}</span>
        </template>

        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t('common.show') }}</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t('common.entries') }}</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    :placeholder="$t('common.search')"
                  />
                  <!--b-button
                    variant="primary"
                    @click="isAddNewDrinkSidebarActive = true"
                  >
                    <span class="text-nowrap">Add Drink</span>
                  </b-button-->

                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="DownloadIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item @click="fetchDrinksFile()">
                      <feather-icon icon="AlignJustifyIcon" />
                      <span class="align-middle ml-50">{{ $t('common.save_xlsx') }}</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      :disabled="true"
                      @click="fetchSalesFile('pdf')"
                    >
                      <feather-icon icon="BookIcon" />
                      <span class="align-middle ml-50">{{ $t('common.save_pdf') }}</span>
                    </b-dropdown-item>
                  </b-dropdown>

                  <b-button
                    variant="flat-info"
                    class="btn-icon rounded-circle"
                    @click="refetchData"
                  >
                    <feather-icon
                      icon="RefreshCwIcon"
                      size="18"
                    />
                  </b-button>

                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
            ref="refDrinkListTable"
            v-model="visibleRows"
            class="position-relative"
            :items="fetchDrinks"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >

            <!-- Column: Drink -->
            <template #cell(name)="data">
              <b-media vertical-align="center">
                <b-link
                  :to_="{ name: 'apps-drinks-view', params: { id: data.item.id } }"
                  :to="{ name: 'apps-drinks-edit', params: { id: data.item.id } }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ data.item.name }}
                </b-link>
                <small class="text-muted">@{{ data.item.serial }}</small>
              </b-media>
            </template>

            <template #cell(price)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ parseFloat(data.item.price).toFixed(2) }}</span>
              </div>
            </template>

            <!-- Column: Object  -->
            <template #cell(machine_id)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ resolveDrinkMachineText(data.item.machine_id) }}</span>
              </div>
            </template>

            <!-- Column: Company -->
            <template #cell(company_id)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ resolveDrinkCompanyText(data.item.companyId) }}</span>
              </div>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveDrinkStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ data.item.status }}
              </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!--b-dropdown-item :to="{ name: 'apps-drinks-view', params: { id: data.item.id } }">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Details</span>
                </b-dropdown-item-->

                <b-dropdown-item :to="{ name: 'apps-drinks-edit', params: { id: data.item.id } }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteDrink(data.item)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template slot="bottom-row">
              <th>{{ $t('common.total') }}</th>
              <template
                v-for="(field, i) in tableColumns"
              >
                <th
                  v-if="i > 0"
                  :key="i"
                >
                  {{ totalColumn(field.key).toFixed(0) }}
                </th>
              </template>
              <td />
              <!-- this is a computed prop that adds up all the expenses in the visible rows -->
              <!--th>{{ totalPrice.toFixed(2) }}</th>
              <th>{{ totalQuantity.toFixed(0) }}</th>
              <td /-->
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ $t('common.showing') }} {{ dataMeta.from }} {{ $t('common.to') }} {{ dataMeta.to }} {{ $t('common.of') }} {{ dataMeta.of }} {{ $t('common.entries') }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalDrinks"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-tab>

      <b-tab
        @click="ApexChartsExec()"
      >
        <template #title>
          <feather-icon
            icon="CpuIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('components.chart') }}</span>
        </template>

        <b-card
          no-body
          class="card-revenue-budget"
        >
          <b-row class="mx-0">
            <b-col
              md="12"
              class="revenue-report-wrapper"
            >
              <div class="d-sm-flex justify-content-between align-items-center mb-3">
                <h4 class="card-title mb-50 mb-sm-0">
                  {{ $t('components.report') }}
                </h4>
                <div class="d-flex align-items-center">
                  <!--div class="d-flex align-items-center mr-2">
                <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
                <span>Drinks, pcs</span>
              </div-->
                  <!--div class="d-flex align-items-center">
                <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
                <span>Expense</span>
              </div-->
                </div>
              </div>

              <!-- chart -->
              <vue-apex-charts
                v-if="series[0].data.length > 0"
                id="revenue-report-chart"
                type="bar"
                :height="chartOptions.chart.height"
                :options="chartOptions"
                :series="series"
              />
            </b-col>

          </b-row>
        </b-card>

      </b-tab>

    </b-tabs>

  </div>
</template>

<script>
import {
  // BCard, BRow, BCol, BFormInput,
  BButton,
  // BTable, BMedia, BAvatar, BLink,
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BTab, BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, watch, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import VueApexCharts from 'vue-apexcharts'
import ApexCharts from 'apexcharts'
import store from '@/store'
import DrinksListFilters from './DrinksListFilters.vue'
import useDrinksList from './useDrinksList'
import drinkStoreModule from '../drinkStoreModule'

export default {
  components: {
    DrinksListFilters,
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    // BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    VueApexCharts,
  },
  data() {
    return {
    }
  },
  computed: {
    totalPrice() {
      // console.log(this)
      // eslint-disable-next-line
      return this.visibleRows.reduce((accum, item) => {
        return accum + parseFloat(item.price)
      }, 0.00)
    },
    totalQuantity() {
      // console.log(this)
      // eslint-disable-next-line
      return this.visibleRows.reduce((accum, item) => {
        return accum + parseFloat(item.quantity)
      }, 0.00)
    },
  },
  setup(_, { root }) {
    // console.log(props)
    const USER_APP_STORE_MODULE_NAME = 'app-drink'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, drinkStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const visibleRows = ref([])
    const totalColumn = key =>
      // eslint-disable-next-line
       visibleRows.value.reduce((accum, item) => {
        // console.log(accum, item)
        // eslint-disable-next-line
        if (!item[key]) item[key] = 0
        return accum + parseFloat(item[key])
      }, 0)
    const isAddNewDrinkSidebarActive = ref(false)
    /*
    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]
    /*
    const companyOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]
    */
    const statusOptions = [
      // { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]
    const {
      fetchDrinks,
      fetchDrinksFile,
      tableColumns,
      perPage,
      currentPage,
      totalDrinks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDrinkListTable,
      refetchData,
      // UI
      resolveDrinkRoleVariant,
      resolveDrinkRoleIcon,
      resolveDrinkStatusVariant,
      resolveDrinkCompanyText,
      resolveDrinkMachineText,
      // Extra Filters
      machineFilter,
      companyFilter,
      objectFilter,
      statusFilter,
      machineOptions,
      companyOptions,
      objectOptions,
      deleteDrink,
      series,
      chartOptions,
      chartData,
      rangePicker,
    } = useDrinksList()
    const ApexChartsExec = () => {
      root.$nextTick(() => {
        // console.log('TAB CLICK')
        if (chartOptions && chartOptions.value) ApexCharts.exec('chartDrink', 'updateOptions', chartOptions.value)
      })
    }
    watch(chartData, () => {
      if (chartOptions.value) {
        if (this?.$refs?.chart) {
          ApexCharts.exec('chartSales', 'updateOptions', chartOptions.value)
        }
      }
    })
    return {
      // Sidebar
      isAddNewDrinkSidebarActive,
      fetchDrinks,
      fetchDrinksFile,
      tableColumns,
      perPage,
      currentPage,
      totalDrinks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDrinkListTable,
      refetchData,
      // Filter
      avatarText,
      // UI
      resolveDrinkRoleVariant,
      resolveDrinkRoleIcon,
      resolveDrinkStatusVariant,
      resolveDrinkCompanyText,
      resolveDrinkMachineText,
      machineOptions,
      companyOptions,
      objectOptions,
      statusOptions,
      // Extra Filters
      machineFilter,
      companyFilter,
      objectFilter,
      statusFilter,
      deleteDrink,
      visibleRows,
      series,
      chartOptions,
      ApexChartsExec,
      totalColumn,
      rangePicker,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
