import {
  ref, watch, computed,
} from '@vue/composition-api'
import { title } from '@core/utils/filter'
import Vue from 'vue'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import moment from 'moment'
import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n'

export default function useDrinksList() {
  // console.log(router.currentRoute.params)

  // Use toast
  const toast = useToast()

  const refDrinkListTable = ref(null)

  // Table Handlers
  const tableColumnsTemplate = [
    {
      key: 'machine_id',
      label: i18n.t('components.machine'),
      formatter: title,
      sortable: true,
    },
    /*
    {
      key: 'timeDrink',
      label: 'Time',
      formatter: title,
      sortable: false,
    },
    { key: 'pLU', sortable: false },
    { key: 'beverageName', label: 'Beverage', sortable: false },
    {
      key: 'price',
      label: 'Price',
      formatter: title,
      sortable: false,
    },
    { key: 'quantity', sortable: false },
    { key: 'details', sortable: false },
    { key: 'type', sortable: false },
    */
    /*
    {
      key: 'drink',
      label: 'Drink',
      formatter: title,
      sortable: true,
    },
    {
      key: 'description',
      label: 'Description',
      formatter: title,
      sortable: true,
    },
    */
  ]

  const tableColumns = ref([...tableColumnsTemplate])

  const perPage = ref(10)
  const totalDrinks = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 50, 250, 500]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const rangePicker = ref([moment().set({
    hour: 0, minute: 0, second: 0, millisecond: 0,
  }).format('YYYY-MM-DD HH:mm')
    .toString(), moment().set({
    hour: 23, minute: 59, second: 0, millisecond: 0,
  }).format('YYYY-MM-DD HH:mm')
    .toString()])

  if (router.currentRoute.params.machineFilter) {
    store.commit('appConfig/UPDATE_MACHINE_FILTER', router.currentRoute.params.machineFilter)
    store.commit('appConfig/UPDATE_OBJECT_FILTER', null)
    store.commit('appConfig/UPDATE_COMPANY_FILTER', null)
  }

  const companyFilter = ref(store.state.appConfig.filters.company ? store.state.appConfig.filters.company : null)
  const objectFilter = ref(store.state.appConfig.filters.object ? store.state.appConfig.filters.object : [])
  const machineFilter = ref(store.state.appConfig.filters.machine ? store.state.appConfig.filters.machine : null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refDrinkListTable.value ? refDrinkListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDrinks.value,
    }
  })

  const chartData = ref(new Date())

  const series = ref([{
    data: [],
  }])

  // eslint-disable-next-line
  const chartOptions = ref({
    chart: {
      type: 'bar',
      height: 0,
      id: 'chartDrink',
      toolbar: {
        // show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 15,
        endingShape: 'rounded',
        barHeight: '100%',
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'bottom',
        },
      },
    },
    // colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
    //   '#f48024', '#69d2e7',
    // ],
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#000000'],
      },
      formatter(val, opt) {
        return `${opt.w.globals.labels[opt.dataPointIndex]}:  ${val}`
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
        color: '#aaaaaa',
        blur: 5,
        opacity: 0.7,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    /*
    title: {
      text: 'Custom DataLabels',
      align: 'center',
      floating: true,
    },
    subtitle: {
      text: 'Category Names as DataLabels inside bars',
      align: 'center',
    },

     */
    tooltip: {
      show: false,
      theme: 'dark',
      x: {
        show: false,
      },
      y: {
        show: false,
        title: {
          formatter() {
            return ''
          },
        },
      },
    },
  })
  const fetchDrinks = (ctx, callback) => {
    store
      .dispatch('app-drink/fetchDrinks', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        machine: machineFilter.value,
        object: objectFilter.value,
        company: companyFilter.value,
        status: statusFilter.value,
        dateRange: rangePicker.value,
      })
      .then(response => {
        // console.log('response')
        const {
          // eslint-disable-next-line
          seriesdata, xaxis, columns, drinks, total,
        } = response.data
        tableColumns.value = [
          ...tableColumnsTemplate,
          ...columns,
        ]
        // console.log('Before')
        // console.log('Before', chartOptions.value.chart.height)
        chartOptions.value.xaxis.categories = xaxis
        chartOptions.value.chart.height = 85 + 30 * xaxis.length
        // chartOptions.value = {}
        // console.log('After', chartOptions.value.chart.height)

        // console.log('Before', series.value[0].data)
        series.value = [{ data: seriesdata }]
        // console.log('After', series.value[0].data)

        chartData.value = new Date()
        if (callback) {
          callback(drinks)
        }
        totalDrinks.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching drinks list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    fetchDrinks()
    refDrinkListTable.value.refresh()
  }
  watch([currentPage, perPage, searchQuery, machineFilter, companyFilter, objectFilter, statusFilter, rangePicker], () => {
    if (!companyFilter.value) {
      localStorage.removeItem('companyId')
    }
    refetchData()
  })

  const fetchDrinksFile = (type = 'xlsx') => {
    store
      .dispatch('app-drink/fetchDrinks', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        machine: machineFilter.value,
        object: objectFilter.value,
        company: companyFilter.value,
        status: statusFilter.value,
        dateRange: rangePicker.value,
        type,
      })
      .then(response => {
        const { data } = response
        const buf = Buffer.from(data.data)
        const contentType = type === 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf'
        const blob = new Blob([buf], { type: contentType })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `Components.${type}`
        link.click()
        URL.revokeObjectURL(link.href)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching export file',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteDrink = item => {
    // console.log(Vue.swal)
    // console.log(store._vm)
    Vue.swal({
      title: 'Are you sure?',
      html: `Drink <b>${item.name}</b> will be deleted. \nYou won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        store
          .dispatch('app-drink/deleteDrink', { id: item.id })
          .then(() => {
            refetchData()
            Vue.swal({
              icon: 'success',
              title: 'Deleted!',
              text: `Drink ${item.name} has been deleted.`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Drink delete error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    })
  }

  const machineOptions = ref([])
  store.dispatch('app-drink/fetchMachines')
    .then(response => {
      // console.log('RESP', response.data)
      machineOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching machines list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const companyOptions = ref([])
  store.dispatch('app-drink/fetchCompanies')
    .then(response => {
      // console.log('RESP', response.data)
      companyOptions.value = response.data.data
      // console.log('companyOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching companies list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const objectOptions = ref([])
  store.dispatch('app-drink/fetchObjects')
    .then(response => {
      // console.log('RESP', response.data)
      objectOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching roles list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveDrinkStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolveDrinkCompanyText = company => {
    if (!company) {
      return ''
    }
    try {
      return companyOptions.value.find(o => o.value === company).label
    } catch (e) {
      return 'undefined'
    }
  }

  const resolveDrinkMachineText = machine => {
    if (!machine) {
      return ''
    }
    try {
      return machineOptions.value.find(o => o.value === machine).label
    } catch (e) {
      return 'undefined'
    }
  }

  return {
    fetchDrinks,
    fetchDrinksFile,
    tableColumns,
    perPage,
    currentPage,
    totalDrinks,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDrinkListTable,

    resolveDrinkStatusVariant,
    resolveDrinkCompanyText,
    resolveDrinkMachineText,
    refetchData,

    // Extra Filters
    machineFilter,
    companyFilter,
    objectFilter,
    statusFilter,

    companyOptions,
    machineOptions,
    objectOptions,

    deleteDrink,

    series,
    chartOptions,
    chartData,

    rangePicker,

  }
}
